.ticket-container {
  box-shadow: 0px 1px 8px 0px #63676733;
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 99%;
  margin-left: auto;
  margin-right: auto;

  .price {
    span {
      display: inline !important;
    }
  }
}

.ticket-main-cont {
  border-radius: 10px;
  border: none;
  // width: 100%;
  width: 350px;
  padding: 20px 40px;
  height: auto;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 16px;

  &.discount-cont {
    width: 100%;
  }

  &.additional-cont {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  &::before,
  &::after {
    content: '';
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

  &::before {
    left: -30px;
  }
  &::after {
    right: -30px;
  }
  .content {
    .ticket-button {
      margin-top: 20px;
      background-color: #000;
      border: none;
      border-radius: 9999px;
      padding: 16px 20px;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .dull-color {
    position: absolute;
    width: 65%;
    height: 157%;
    right: -180px;
    background-color: #fff;
    top: 0;
    transform: rotate(122deg);
    z-index: 0;
    opacity: 0.2;
  }
}

// discounts component
.discount-cont {
  .discount-header {
    padding: 16px 30px;
    background-color: #ebedf1;
    border-radius: 8px;
    border: none;

    .heading {
      width: 23%;
    }
  }
  .discount-body {
    padding: 16px 30px;
    border-bottom: 1px solid #ebedf1;

    .body-content {
      font-size: 14px;
      font-weight: 500;
      width: 24%;
    }
    .body-content-edit {
      width: 4%;
      color: #2ba7ca;
    }
  }
}
